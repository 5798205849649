import { useEffect, useState } from "react";
import { Button, Popconfirm, Space, Table } from "antd";
import { Link, useNavigate } from 'react-router-dom';
import styles from "../RequestDocumentUploadList.module.css"
import { RequestDocumentModel } from "../../../../../models/RequestDocumentModel";
import { convertDBDate2Show } from "../../../../../helper/DateTimeHelper";
import { DeleteTwoTone, EditTwoTone, QuestionCircleFilled } from "@ant-design/icons";
import { getStatusTextColorCode, labelLang, revertLang, showRequestDocumentChangeFlg } from "../../../../../helper/HTMLHelper";
import { LanguageState } from "../../../../../store/language/languageSlice";
import { useAppSelector } from "../../../../../store";

interface RequestDocumentUploadListTableSectionProps {
    openAddModal: any;
    removeData: any;
    userId: number;
    dataList: RequestDocumentModel[];
    openSearchDrawer: any;
    tablePageSize: number;
}
const RequestDocumentUploadListTableSection = (props: RequestDocumentUploadListTableSectionProps) => {
    const [dataList, setDataList] = useState<RequestDocumentModel[]>(props.dataList);
    const [userId, setUserId] = useState<number>(props.userId);
    const [tablePageSize, setTablePageSize] = useState<number>(10);

    const lang: LanguageState = useAppSelector(state => state.language);
    const [currentLang, setCurrentLang] = useState<"th" | "en">(lang.currentLang.lang);
    useEffect(() => {
        setCurrentLang(lang.currentLang.lang);
    }, [lang.currentLang])
    
    const columns = [
        {
            title: labelLang('ประเภทคำร้อง', "Request Name", currentLang),
            key: 'doc_name',
            dataIndex: 'doc_name',
            sorter: (a: RequestDocumentModel, b: RequestDocumentModel) => a.doc_name.localeCompare(b.doc_name),
            render: (data: string, item: RequestDocumentModel) => {
                return (
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div style={{marginRight: 5}}>{labelLang(item.doc_name, item.doc_name_en, currentLang)}</div>
                        <div style={{display: 'flex', alignItems: 'center'}}>{showRequestDocumentChangeFlg(item, userId)}</div>
                    </div>
                )
            },
        },
        {
            title: labelLang('ผู้อัปโหลด', "Sender", currentLang),
            key: 'upload_user_name',
            sorter: (a: RequestDocumentModel, b: RequestDocumentModel) => `${labelLang(a.upload_user_firstname, a.upload_user_firstname_en, currentLang)} ${labelLang(a.upload_user_lastname, a.upload_user_lastname_en, currentLang)}`.localeCompare(`${labelLang(b.upload_user_firstname, b.upload_user_firstname_en, currentLang)} ${labelLang(b.upload_user_lastname, b.upload_user_lastname_en, currentLang)}`),
            render: (data: RequestDocumentModel) => {
                return `${labelLang(data.upload_user_firstname, data.upload_user_firstname_en, currentLang)} ${labelLang(data.upload_user_lastname, data.upload_user_lastname_en, currentLang)}`
                    + ` (${labelLang(data.upload_user_firstname, data.upload_user_firstname_en, revertLang(currentLang))} ${labelLang(data.upload_user_lastname, data.upload_user_lastname_en, revertLang(currentLang))})`
            },
        },
        {
            title: labelLang('คณะ', "Faculty", currentLang),
            dataIndex: currentLang == "en" ? 'faculty_name_en' : 'faculty_name',
            key: 'faculty_name',
            sorter: (a: RequestDocumentModel, b: RequestDocumentModel) => a.major_name.localeCompare(b.major_name),
            // render: (data: any) => {data.age},
        },
        {
            title: labelLang('ภาควิชา', "Branch", currentLang),
            dataIndex: currentLang == "en" ? 'branch_name_en' : 'branch_name',
            key: 'branch_name',
            sorter: (a: RequestDocumentModel, b: RequestDocumentModel) => a.branch_name.localeCompare(b.branch_name),
        },
        {
            title: labelLang('สาขา', "Major", currentLang),
            dataIndex: currentLang == "en" ? 'major_name_en' : 'major_name',
            key: 'major_name',
            sorter: (a: RequestDocumentModel, b: RequestDocumentModel) => a.major_name.localeCompare(b.major_name),
            // render: (data: any) => {data.age},
        },
        {
            title: labelLang('วันที่สร้าง', "Sent Date", currentLang),
            key: 'create_date',
            sorter: (a: RequestDocumentModel, b: RequestDocumentModel) => a.create_date.localeCompare(b.create_date),
            render: (data: RequestDocumentModel) => {return convertDBDate2Show(data.create_date)},
        },
        {
            title: labelLang('สถานะปัจจุบัน', "Status", currentLang),
            dataIndex: 'status',
            key: 'status',
            sorter: (a: RequestDocumentModel, b: RequestDocumentModel) => a.status.localeCompare(b.status),
            // render: (data: any) => {data.age},
            render: (data: string, item: RequestDocumentModel, index: number) => {return <span style={{color: getStatusTextColorCode(data)}}>{data}</span>}
        },
        {
          title: 'Action',
          key: 'action',
          render: (text: string, record: any) => (
            <Space size="middle">
                <Link to={`/management/requestdocument/form/${record.id}`}><EditTwoTone /></Link>
                { record.create_user_id === userId && record.status === 'DRAFT' ?
                <Popconfirm 
                    title="ต้องการลบข้อมูลนี้หรือไม่"
                    onCancel={() => {}}
                    onConfirm={() => {removeData(record.id)}}
                    icon={<QuestionCircleFilled />}
                >
                    <DeleteTwoTone />
                </Popconfirm> : null
                }
            </Space>
          ),
        },
    ]

    useEffect(() => {
        setDataList(props.dataList);
    }, [props.dataList])
    useEffect(() => {
        setUserId(props.userId);
    }, [props.userId])
    useEffect(() => {
        setTablePageSize(props.tablePageSize);
    }, [props.tablePageSize])
    const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
        console.log('params: ', pagination, filters, sorter, extra);
    }
    const openAddModal = () => {
        if(props.openAddModal){
            props.openAddModal();
        }
    }
    const openSearchDrawer = () => {
        if(props.openSearchDrawer){
            props.openSearchDrawer();
        }
    }
    const removeData = (deleteId: number) => {
        if(props.removeData){
            props.removeData(deleteId);
        }
    }
    return (
        <div className={styles['list-container']}>
            <div className={styles['button-container']}>
                <Button type={'primary'} onClick={() => {openAddModal()}}>{labelLang("เพิ่มรายการใหม่", "Create Request", currentLang)}</Button>
            </div>
            <Table columns={columns} dataSource={dataList} onChange={onChange} pagination={{pageSize: tablePageSize}}/>
        </div>
    )
}

export default RequestDocumentUploadListTableSection