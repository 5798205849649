import { CheckCircleTwoTone, CloseCircleTwoTone, ExclamationCircleTwoTone } from "@ant-design/icons";
import { RequestDocumentData, RequestDocumentModel } from "../models/RequestDocumentModel";
import { LanguageState } from "../store/language/languageSlice";
import { useAppSelector } from "../store";

export const getStatusTextColorCode = (statusText: string) => {
    if(statusText === "DRAFT"){
        return "#000";
    }else if(statusText === "PENDING"){
        return "#D6C81F";
    }else if(statusText === "APPROVE"){
        return "#36C21A";
    }else if(statusText === "REJECT"){
        return "#E31919";
    }else if(statusText === "REMOVE"){
        return "#0225D5";
    }
    return "#000";
}

export const isShowChangeFlg = (currentStatus: string, ownerUserId: number, currentUserId: number, isShowFlg: number): boolean => {
    return (ownerUserId === currentUserId && currentStatus !== "PENDING" && isShowFlg === 1);
}
export const showChangeFlg = (currentStatus: string, ownerUserId: number, currentUserId: number, isShowFlg: number) => {
    let isShow = isShowChangeFlg(currentStatus, ownerUserId, currentUserId, isShowFlg);
    if(isShow){
        if(currentStatus === "DRAFT"){
            return <ExclamationCircleTwoTone style={{fontSize: 16}} twoToneColor="#D6C81F" />
        }
        // else if(currentStatus === "APPROVE"){
        //     return <CheckCircleTwoTone style={{fontSize: 16}} twoToneColor="#36C21A" />
        // }else if(currentStatus === "REJECT"){
        //     return <CloseCircleTwoTone style={{fontSize: 16}} twoToneColor="#E31919" />
        // }
    }
    return null;
}
export const isShowRequestDocumentChangeFlg = (data: RequestDocumentModel | RequestDocumentData, currentUserId: number) => {
    return isShowChangeFlg(data.status, data.create_user_id, currentUserId, data.status_change_flg)
}
export const showRequestDocumentChangeFlg = (data: RequestDocumentModel | RequestDocumentData, currentUserId: number) => {
    return showChangeFlg(data.status, data.create_user_id, currentUserId, data.status_change_flg)
}
export const labelLang = (th: string | null | undefined, en: string | null | undefined, currentLang: "th" | "en") => {
    let cleanTH = th == null ? "" : th;
    return currentLang === "en" && en != null && en.trim() !== "" ? en : cleanTH;
}
export const revertLang = (currentLang: "th" | "en") => {
    if(currentLang == "th"){
        return "en"
    }else{
        return "th"
    }
}